export const getToken = () => {
    return localStorage.getItem('Token')
}

export const Roleactive = {
    roleMap: {
        "1": "管理员",
        "2": "编导",
        "3": "剪辑",
        "4": "运营",
        "5": "查看",
    },
    array: function (role: string[]) {
        if (!role) {
            return [];
        }

        const result = [];
        role.forEach((r) => {
            const trimmedRole = r.trim();
            if (this.roleMap[trimmedRole]) {
                result.push(this.roleMap[trimmedRole]);
            }
        });
        return result;
    },

    string: function (role: string[]) {
        if (!role) {
            return "";
        }
        const result = [];
        role.forEach((r) => {
            const trimmedRole = r.trim();
            if (this.roleMap[trimmedRole]) {
                result.push(this.roleMap[trimmedRole]);
            }
        });
        return result.join(",");
    },
};

export const handeTime = (updated_at) => {
    const date = new Date(updated_at * 1000);
    return date.toLocaleString();
};
