import { makeAutoObservable } from "mobx";
import { Acr_Login, login_feishu, Login_geturl } from "../../server/Login";
import { Message } from "@arco-design/web-react";
import { getPreconalCenyerr } from "../../server/PersonalCenter";


class penCanterStore {
    token = null;
    user_info = null;

    request_id = {};
    uuid = {};
    isSuccess = false;
    isLoading = false;
    team_id = null;
    item_user_info = {}

    constructor() {
        makeAutoObservable(this);

    }



    /***
     * get 个人中心 信息
    */
    getPreconalCenyerr_to(props) {
        try {
            return getPreconalCenyerr(props).then((res) => {
                console.log(res, 'resresres');
                if (res.code == 0 && res.message == "OK") {
                    this.item_user_info = res.data.item
                }
                console.log(this.item_user_info);

            });
        } catch (error) {
            console.error(error);
        }
    }




}
const penCanterStoreOP = new penCanterStore();
export default penCanterStoreOP;
