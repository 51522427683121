import React,{Suspense} from 'react';
import logo from './logo.svg';

import { useNavigate, RouterProvider } from 'react-router-dom'
import routers from './router';
import './App.less';
function App() {
  return (
    <Suspense>
      <RouterProvider router={routers}/>
    </Suspense>
  );
}

export default App;
