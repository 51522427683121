import { Navigate, Outlet, createBrowserRouter } from "react-router-dom";
import { KeepAlive } from "react-activation";
import { Suspense, lazy } from "react";
const Login = lazy(() => import("../pages/Login/index"));
const ListTo = lazy(() => import("../pages/ListTo/index"));
const Role = lazy(() => import("../pages/Role/index"));
const RoleDetils = lazy(() => import("../pages/Role/components/Detils"));
const Homeitem = lazy(() => import("../pages/Home/Homeitem"));
const Home = lazy(() => import("../pages/Home/index"));
const Paymentorder = lazy(() => import("../pages/Order/Paymentorder"));
const Refundorder = lazy(() => import("../pages/Order/Refundorder"));
const Equityorder = lazy(() => import("../pages/Order/Equityorder"));
const HotScript = lazy(() => import("../pages/ContentManage/HotScript"));
const HotVideo = lazy(() => import("../pages/ContentManage/HotVideo"));
const ClicentManage = lazy(() => import("../pages/ClientManage/index"));
const ScriptDetail = lazy(
  () => import("../pages/ContentManage/HotScript/ScriptDetail"),
);
const HotVideoDetail = lazy(
  () => import("../pages/ContentManage/HotVideo/VideoDetail"),
);

const LoginLanding = lazy(() => import("../pages/LoginLanding/index"));
/*
  此文件为铺平路由  
  树级路由在 Menu  文件中  Memudata  定义搜索关键词 ---》》   RouterList

  level 层级 
  parentId 父级路由 


*/
const routers = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/login/feishu",
    element: <LoginLanding />,
  },
  {
    path: "/",
    element: <Navigate to="/home" />,
  },
  {
    path: "/",
    element: <Suspense><ListTo /></Suspense>,
    handle: {
      name: '工作台'
    },
    children: [
      {
        path: "home",
        // element: <Home/>,
        element: <Suspense><Home /></Suspense>,
      },
      {
        path: "personal",
        element: <Suspense><Homeitem /></Suspense>,
        handle: {
          name: '个人中心',
          role: ['admin']
        }
      },
      {
        path: "client",
        element: <ClicentManage />,
        handle: {
          name: '客户管理',
        }
      },
      {
        path: "role",
        handle: {
          name: '用户管理'
        },
        children: [
          {
            index: true,
            element: <Suspense><Role /></Suspense>,
          },
          {
            path: 'detail',
            element: <Suspense><RoleDetils /></Suspense>,
            handle: {
              name: '用户详情'
            },
          }
        ]
      },
      {
        path: "order",
        children: [
          {
            path: 'Paymentorder',
            handle: {
              name: '支付订单',
            },
            children: [
              {
                index: true,
                element: <Suspense><Paymentorder /></Suspense>,
              },
              {
                path: "Refundorder",
                element: <Refundorder />,
                handle: {
                  name: '开票'
                }
              },
            ],
          },
          {
            path: "Equityorder",
            element: <Suspense><Equityorder /></Suspense>,
            handle: {
              name: '权益订单',
            }
          }
        ],
      },
      {
        path: "contentManage",
        children: [
          {
            path: "hotScript",
            handle: {
              name: '爆款脚本',
            },
            children: [
              {
                index: true,
                element: (
                  <KeepAlive cacheKey="hotScript">
                    <Suspense>
                      <HotScript />
                    </Suspense>
                  </KeepAlive>
                ),
              },
              {
                path: "detail",
                element: <ScriptDetail />,
                handle: {
                  name: '爆款脚本详情',
                },
              },
            ]
          },
          {
            path: "hotVideo",
            handle: {
              name: '爆款视频',
            },
            children: [
              {
                index: true,
                element: (
                  <KeepAlive cacheKey="hotVideo">
                    <Suspense>
                      <HotVideo />
                    </Suspense>
                  </KeepAlive>
                ),
              },
              {
                path: "detail",
                element: <HotVideoDetail />,
                handle: {
                  name: '爆款视频详情',
                },
              },
            ]
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/login" />,
  },
]);

export default routers;
