import axios from "axios";
import { baseUrl } from "./baseUrl";
// import http from "./proxy"
// const axios = http
export async function Acr_Login(params: any): Promise<any> {
    try {
        const res = await axios.post(`/api/v1/admin/user/login`, params);
        if (res) {
            return res;
        } else {
            console.log("接口错误");
        }
    } catch (err) {
        return err;
    }
}

export async function Login_geturl(params: any): Promise<any> {
    try {
        const res = await axios.get(`/api/v1/admin/feishu/authorize`, params);
        if (res) {
            return res;
        } else {
            console.log("接口错误");
        }
    } catch (err) {
        return err;
    }
}




export async function login_feishu(params: any): Promise<any> {
    try {
        const res = await axios.post(`/api/v1/admin/feishu/login`, params);
        if (res) {
            return res;
        } else {
            console.log("接口错误");
        }
    } catch (err) {
        return err;
    }
}