/* eslint-disable no-prototype-builtins */
import axios, { AxiosRequestConfig } from "axios";
import { putApi } from "./proxy";
// import http from "./proxy"
// const axios = http

export async function Acr_Login(params: any): Promise<any> {
    try {
        const res = await axios.post("/api/v1/admin/user/login", params);
        if (res) {
            return res;
        } else {
            console.log("接口错误");
        }
    } catch (err) {
        return err;
    }
}



export async function setadmin_userper(params: any): Promise<any> {
    try {
        const res = await axios.put(`/api/v1/admin/user/${params.id}`, params);
        if (res) {
            return res;
        } else {
            console.log("接口错误");
        }
    } catch (err) {
        return err;
    }
}


export async function getmerberlist(data: any): Promise<any> {
    const query = `
        {user_lists(field: "${data.field || ""}", status: ${data.status || 0}, source: ${data.source || 0},  promoter_field: "",  user_type: 1, start_time: ${data.start_time || 0}, end_time: ${data.end_time || 0}, page: ${data.page}, page_size: 20){
    total
    item {
        id
        uuid
        phone_number
        nickname
        source
        status
        created_at
        updated_at
        team_count
    }
  }}`;
    try {
        const res = await axios.post(
            `/api/v1/graphql/backend`,
            ` ${query}`
            ,
            {
                headers: {
                    "Content-type": "application/graphql",
                    Token: localStorage.getItem("Token") || "",
                },
            });
        if (res) {
            return res;
        } else {
            console.log("接口错误");
        }
    } catch (err) {
        return err;
    }
}

export async function getrolemerbeopdo(data: any): Promise<any> {
    const query = `
        {user_detail(id: ${data.id}){
    user_item {
        id
        uuid
        phone_number
        nickname
        avatar
        avatar_color
        last_login
        last_login_ip
        source
        status
        register_method
        user_type
        auth_status
        created_at
        updated_at
        team_count
    }
    personal_item {
        id
        name
        identity_card
        front_image_url
        back_image_url
        status
        reason
        created_at
        updated_at
    }
  }}`;
    try {
        const res = await axios.post(
            `/api/v1/graphql/backend`,
            ` ${query}`
            ,
            {
                headers: {
                    "Content-type": "application/graphql",
                    Token: localStorage.getItem("Token") || "",
                },
            });
        if (res) {
            return res;
        } else {
            console.log("接口错误");
        }
    } catch (err) {
        return err;
    }
}

export async function getteamdata(data: any): Promise<any> {
    const query = `
        {user_teams(member_uuid: "${data.uuid}", role_id: 0, start_at: 0, end_at: 0, team_id: 0, state: 0){
    team_id
    team_name
    count
    role_ids
    created_at
    creator_uuid
    creator_name
    admin
    admin_name
    state
    package_active
    company
  }}`;
    try {
        const res = await axios.post(
            `/api/v1/graphql/backend`,
            ` ${query}`
            ,
            {
                headers: {
                    "Content-type": "application/graphql",
                    Token: localStorage.getItem("Token") || "",
                },
            });
        if (res) {
            return res;
        } else {
            console.log("接口错误");
        }
    } catch (err) {
        return err;
    }
}

interface getidQyListprops {
    ids: Array<any>
}
function convertParamsToQueryString(params: any) {
    const queryParts = [];

    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            const value = params[key];

            // 跳过未定义的键值
            if (value === undefined || value === null) {
                continue;
            }

            // 如果值是数组，逐个添加
            if (Array.isArray(value) && value.length > 0) {
                value.forEach((item) => {
                    if (item !== undefined && item !== null && item !== "") {
                        queryParts.push(
                            `${encodeURIComponent(key)}=${encodeURIComponent(item)}`,
                        );
                    }
                });
            }
            // 如果值是字符串且包含逗号，则拆分并逐个添加
            else if (typeof value === "string" && value.includes(",")) {
                value.split(",").forEach((item) => {
                    const trimmedItem = item.trim();
                    if (trimmedItem !== "") {
                        queryParts.push(
                            `${encodeURIComponent(key)}=${encodeURIComponent(trimmedItem)}`,
                        );
                    }
                });
            }
            // 如果值是非空的字符串或其他类型，直接添加
            else if (value !== "") {
                queryParts.push(
                    `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
                );
            }
            // 如果值是空字符串，添加键但不添加值
            else {
                queryParts.push(`${encodeURIComponent(key)}=`);
            }
        }
    }

    return queryParts.join("&");
}
export async function getidQyList(params: any): Promise<any> {
    try {
        const res = await axios.get(`/api/v1/admin/order/name?${convertParamsToQueryString(params)}`);
        if (res) {
            return res.data;
        } else {
            console.log("接口错误");
        }
    } catch (err) {
        return err;
    }
}

export async function getteamorder(params: any): Promise<any> {
    try {
        const res = await axios.get(`/api/v1/admin/order/name`, { params });
        if (res) {
            return res;
        } else {
            console.log("接口错误");
        }
    } catch (err) {
        return err;
    }
}


export async function getidQYinfo(params: any): Promise<any> {
    try {
        const res = await axios.get(`/api/v1/admin/order/${params.id}/details`);
        if (res) {
            return res.data;
        } else {
            console.log("接口错误");
        }
    } catch (err) {
        return err;
    }
}

export async function getroloedata(): Promise<any> {
    try {
        const res = await axios.get("/api/v1/admin/user",);
        if (res) {
            return res.data;
        } else {
            console.log("接口错误");
        }
    } catch (err) {
        return err;
    }
}

export async function updatasetpost(data: any): Promise<any> {
    const formData = new FormData();
    formData.append('file_type', 'image');
    formData.append('file', data);
    try {
        const res = await axios.post(`/api/v1/admin/feishu/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        if (res) {
            return res.data;
        } else {
            return Promise.reject();
        }
    } catch (err) {
        return Promise.reject(err);
    }
}

export async function createorder(data: any): Promise<any> {
    console.log(data);

    try {
        const res = await axios.post("/api/v1/admin/order", data
        );
        if (res) {
            return res.data;
        } else {
            console.log("接口错误");
        }
    } catch (err) {
        return err;
    }
}

export async function changeRoleStatusApi(data: any): Promise<any> { 
    try {
        const res = await putApi(`/admin/user/${data.id}/status`, {status: data.status});
        if (res) {
            return res.data;
        } else {
            console.log("接口错误");
        }
    } catch (err) {
        return Promise.reject(err);
    }
}




