import { makeAutoObservable } from "mobx";


import { Message } from "@arco-design/web-react";
import http from "http"
import { getorderbenefit, getorderlist, order_addinvoice } from "../../server/Order";
interface Orderdetails {
    amount: number,
    invoiced_amount: number,
    feishu_instance: string
}
class Order {

    orderdata = [

    ]
    paymentoder = [

    ]
    orderdetail = {
        money: 0,
        fscode: ""
    }
    Equityorder = []

    constructor() {
        makeAutoObservable(this);

    }


    savaorderdetalis(data: Orderdetails) {
        this.orderdetail = {
            money: data.amount - data.invoiced_amount,
            fscode: data.feishu_instance
        }
    }






    // 支付订单数据
    async getorderlist_to(props?: any) {
        try {
            await getorderlist(props ? props : {
                name: "",
                title: '',
                shstatus: "",
                xsid: "",
                kpstatue: "",
                fs_type: "",
                state_trim: "",
                end_trim: ""
            }).then((res) => {
                if (res) {
                    if (res?.data?.data) {
                        this.orderdata = res.data.data.order_list
                    }
                    return false
                } else {
                    Message.info(res);
                }
            });
        } catch (error) {
            console.error(error);
        }
    }




    // 权益·订单数据 
    getorderbenefit_to(props?: any) {
        try {
            getorderbenefit(props ? props : {
                name: "",
                title: '',
                xsid: null,
                kpstatue: null,
                state_trim: "",
                end_trim: ""
            }).then((res) => {
                if (res.data?.data.benefit_orders) {
                    console.log(res.data.data.benefit_orders);

                    this.Equityorder = res.data.data.benefit_orders
                } else {
                    Message.info(res.data.errors[0].message);
                }
            });
        } catch (error) {
            console.error(error);
        }
    }




    async order_addinvoice_to(props?: any) {
        try {
            return await order_addinvoice(props).then((res) => {
                if (res?.data.code == 0 && res?.data.message == "OK") {
                    return true
                } else {
                    Message.info(res);
                }
            });
        } catch (error) {
            console.error(error);
        }
    }

}
const Orderstore = new Order();
export default Orderstore;
