import axios from "axios";
import { Message } from "@arco-design/web-react";
import { getToken } from "../uitls";
import qs from "qs";
// eslint-disable-next-line no-undef
export const baseUrl =
  process.env.NODE_ENV === "development"
    ? "http://36.7.72.86:8081"
    : "https://dev.api.zxaigc.com";
// axios.plugin("baseUrlPlugin", (config) => {
//   if (config.url) {
//     config.url = `${baseUrl}/${config.url}`;
//   }
//   return config;
// });

// import axios from 'axios';
// eslint-disable-next-line no-undef
// const baseUrl = process.env.NODE_ENV === "development" ? "http://36.7.72.86:8081" : "https://dev.api.zxaigc.com";
const http = axios.create();

http.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers["Token"] = `${token}`;
    }
    else {
      window.location.href = "/login";
      return Promise.reject(new Error('Token过期，请重新登录'));
    }
    if (config.url && !config.url.startsWith("http")) {
      // 避免重复添加baseUrl
      // config.url = `${baseUrl}${config.url.startsWith("/") ? config.url : "/" + config.url}`;
      config.url = `/api/v1${config.url}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// 响应拦截器
http.interceptors.response.use(
  (response) => {
    // console.log(response, "response");
    if (response.status === 200 || response.status === 207) {
      if (response.data.code === 0) {
        return Promise.resolve(response.data);
      } else {
        // Message.error(response.data.message);
        return Promise.reject(response.data.message);
      }
    }

    return Promise.reject("请求失败");
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      window.localStorage.clear();
      window.location.href = "/login";
      return;
    }

    return Promise.reject(error);
  },
);

export default http;

export const getApi = (url: string, params = {}, isArray=false): Promise<any> => {
  return http({
    url,
    method: "GET",
    params,
    paramsSerializer: isArray ? params => {
      return qs.stringify(params, { indices: false })
    } : undefined,
  })
}

export const postApi = (url: string, data = {}, params = {}): Promise<any> => {
  return http({
    url,
    method: 'post',
    data,
    params,
  });
}

export const putApi = (url: string, data = {}, params = {}): Promise<any> => {
  return http({
    url,
    method: 'put',
    data,
    params,
  });
}