import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.less';
import App from './App';
import {Provider} from "mobx-react"
import store from "./Store/index"
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from "react-router-dom";
import { AliveScope } from 'react-activation';
import "@arco-design/web-react/dist/css/arco.css";
import './assets/iconfont/iconfont.css'
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider {...store}>
    <AliveScope>
      <App />
    </AliveScope>
  </Provider>
);
reportWebVitals();
