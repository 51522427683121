import { makeAutoObservable } from "mobx";
import { Acr_Login, login_feishu, Login_geturl } from "../../server/Login";
import { Message } from "@arco-design/web-react";


class Login_to {
  token = null;
  user_info = null;

  request_id = {};
  uuid = {};
  isSuccess = false;
  isLoading = false;
  team_id = null;

  constructor() {
    makeAutoObservable(this);
  }

  Acr_Login_to(props?: any) {
    try {
      Acr_Login(props).then((res) => {
        console.log(res);

        // if (res?.code == 0 && res?.message == "OK") {
        //   Message.success("成员状态修改成功");
        //   this.team_merber_search_to(this.topmeberdata);
        // } else {
        //   Message.info(res);
        // }
      });
    } catch (error) {
      console.error(error);
    }
  }


  Login_geturl_to(props?: any) {
    try {
      return Login_geturl(props).then((res) => {
        if (res.data && res.status == 200) {
          return res.data.redirect_url
        }

      });
    } catch (error) {
      console.error(error);
    }
  }
  /***
   * 修改状态
   */


  login_feishu_to(props?: any) {
    try {
      return login_feishu(props).then((res) => {
        console.log(res, res.data);
        if (res.data.code == 0 && res.data.message) {
          localStorage.setItem("Token", res.data.data.token)
          localStorage.setItem("user_info", JSON.stringify(res.data.data.info))
          this.isLoading = true
          Message.success('登录成功')
        }

      });
    } catch (error) {
      console.error(error);
    }
  }
}
const Login = new Login_to();
export default Login;
